<template>
  <!--TODO: call convertToObj asap -->
  <div class="post-box">
    <span class="post-title">{{ post.title }}</span>
    <span class="post-username">@{{ post.username }}</span>
    <span class="post-content">{{ post.content }}</span>
  </div>
</template>

<script>
export default {
  name: 'Post',
  props: {
    post: {},
  },
}
</script>
<style scoped>
  .post-box {
    border: 4px;
    border-style: solid;
    border-radius: 15px;
    display: block;
    margin-bottom: 25px;
    overflow-wrap: break-word;
    width: 500px;
  }
  .post-title {
    display: inline-block;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    text-align: left;
    width: 480px;
  }
  .post-username {
    display: inline-block;
    font-style: italic;
    padding-left: 20px;
    text-align: left;
    width: 480px;
  }
  .post-content {
    border-top-color: cornflowerblue;
    border-top-style: solid;
    border-top-width: 1px;
    display: inline-block;
    margin-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-top: 10px;
    text-align: left;
    width: 468px;
  }
</style>
