<template>
  <div v-if="networkUp" class="network-up">
    <NewPost @submitted="prependPosts"></NewPost>
    <Post
      v-for="post in allPosts"
      :post="post"
      :key="post"
    ></Post>
  </div>
  <div v-else-if="attemptedConnection" class="network-down">Looks like we're having some issues connecting to the server. Try reconnecting later.</div>
</template>

<script>
import Post from './components/Post.vue'
import NewPost from './components/NewPost.vue'

export default {
  name: 'App',
  data() {
    return {
      allPosts: Array,
      networkUp: false,
      attemptedConnection: false,
    }
  },
  beforeCreate() {
    // Fill the allPosts array
    fetch("https://worker.milanmiladinovic.workers.dev/posts")
    .then(response => {
      return response.json()
    })
    .then(data => {
      let arr = data["posts"]
      this.allPosts = new Array()
      // Reversing gives most recent at the top.
      for (let index in arr.reverse()) {
        this.allPosts.push(JSON.parse(arr[index]))
      }
      this.networkUp = true;
    })
    .catch(err => {
      this.attemptedConnection = true;
      this.networkUp = false;
      console.log(err.status)
    })
  },
  methods: {
    // When the user submits a new post, we want to update the frontend
    // without reloading the page.
    prependPosts(newPost) {
      this.allPosts.unshift(newPost)
    }

  },
  components: {
    Post,
    NewPost
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}


.network-up {
  display: inline-block;
}
</style>
