<template>
  <div v-if="success" class="succesful-post-creation">Success!{{successTimer()}}</div>
  <div v-else-if="postFailed" class="failed-post-creation">Error! Post Failed.{{failTimer()}}</div>
  <div v-else-if="!posting" class="create-new-post">
    <button v-on:click="posting=true">Create a new post</button>
  </div>
  <div v-else class="new-post-box">
    <input class="new-post-input" v-model="post.title" placeholder="Title" />
    <input class="new-post-input" v-model="post.username" placeholder="Username" />
    <input class="new-post-input" v-model="post.content" placeholder="Content" />

    <!-- Shows the user their new post in real time. -->
    <Post v-if="nonEmptyPost()" :post="post"></Post>

    <button v-if="!nonEmptyPost()" class="cancel-empty" v-on:click="cancelPost">Cancel</button>
    <div v-else class="new-post-actions">
      <div class="new-post-submit"><button v-on:click="submitPost">Submit</button></div>
      <div class="new-post-submit"><button v-on:click="cancelPost">Cancel</button></div>
    </div>
  </div>
</template>

<script>
import Post from './Post.vue'
export default {
  name: 'NewPost',
  data() {
    return {
      post: {},
      success: false, // true briefly after successful new post
      postFailed: false, // true briefly after failed new post
      notiTimer: null, // we attach a timer to this
      posting: false, // true if user is creating a new post
    }
  },
  methods: {
    // Send new post to Cloudflare Worker KV
    submitPost: function() {
      if (this.post.title == null || this.post.username == null || this.post.content == null) {
        alert("Make sure none of your posts fields are empty!")
        return;
      }

      if (0 < this.post.title.length && 0 < this.post.username.length && 0 < this.post.content.length) {
        fetch("https://worker.milanmiladinovic.workers.dev/posts", {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Access-Control-Request-Method': 'POST',
                'Access-Control-Request-Headers': 'X-PINGOTHER, content-type'
            },
            body: JSON.stringify(this.post)
        })
        .then(response => {
            this.posting = false
            response.text().then(data => console.log(data)) // 'success'
            this.success = true
            // We send the new post to the parent component to render it.
            // If the user refreshes the page, they might not see the post for
            // up to 60 sec (cloudflare workers needs to update)
            this.$emit('submitted', this.post)
        })
        .catch(err => {
            this.posting = false;
            this.postFailed = true
            console.log(err)
        })
      } else {
        // TODO: Show error on DOM, would be nicer.
        alert("Make sure none of your posts fields are empty!")
      }
    },
    // Show a "success" notification for 3 seconds,
    // we destroy the setInterval afterwards.
    successTimer: function() {
      let count = 0
      this.notiTimer = window.setInterval(() => {
        if (count == 1) {
            clearInterval(this.notiTimer)
            this.post = {} // clear the post too.
        }
        this.success = false;
        count++

        console.log("hide alert after 3 seconds");
      }, 3000)
    },
    // Show a "failure" notification for 3 seconds,
    // we destroy the setInterval afterwards.
    failTimer: function() {
      let count = 0
      this.notiTimer = window.setInterval(() => {
        if (count == 1) {
            clearInterval(this.notiTimer)
            this.post = {} // clear the post too.
        }
        this.postFailed = false;
        count++

        console.log("hide alert after 3 seconds");
      }, 3000)
    },
    // Discard the post the user is working on
    cancelPost: function() {
      this.posting = false
      this.post = {} // Reset everything
    },
    // Check that the post is non-empty
    nonEmptyPost: function() {
      let flag = false
      if (this.post.title != null) {
        flag = (0 < this.post.title.length)
      }
      if (this.post.username != null && !flag) {
        flag = (0 < this.post.username.length)
      }
      if (this.post.content != null && !flag) {
        flag = (0 < this.post.content.length)
      }
      return flag
    },
  },
  components: {
    Post,
  }
}
</script>
<style scoped>
  .cancel-empty {
    display: block;
    margin-bottom: 10px;
  }
  .create-new-post {
    display: inline-flex;
    padding-bottom: 20px;
    width: 150px;
  }

  .new-post-box {
    display: block;
  }

  .new-post-input {
    display: block;
    margin-bottom: 10px;
    height: 20px;
  }

  .new-post-actions {
    display: inline-flex;
    padding-bottom: 20px;
  }

  .new-post-submit {
    display: block;
    margin-left: 30px;
  }

  .post-box {
    border-color: #42b983;
  }

  .post-content {
    border: none;
  }

  .succesful-post-creation {
    background: #42b983;
    border-radius: 8px;
    border-style: solid;
    color: white;
    display: inline-block;
    margin-bottom: 10px;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 150px;
  }

  .failed-post-creation {
    background: #b95a42;
    border-radius: 8px;
    border-style: solid;
    color: white;
    display: inline-block;
    margin-bottom: 10px;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 150px;
  }
</style>